export function dataHref() {
  var entries = document.querySelectorAll("*[data-href]");

  for (let entry of entries) {
    let href = entry.getAttribute("data-href");
    let link = entry.querySelector("a[href]");
    if (link) {
      entry.addEventListener("click", (event) => {
        link.click();
      });
      entry.addEventListener("auxclick", (event) => {
        const newEvent = new MouseEvent("click", {
          explicitOriginalTarget: link,
          button: event.button,
          which: event.which,
          buttons: event.buttons,
          bubbles: false,
          cancelable: true,
          view: window,
        });
        link.dispatchEvent(newEvent);
      });
    } else if (href) {
      let clicked = 0;
      entry.addEventListener("mousedown", (event) => {
        clicked = Date.now();
      });
      entry.addEventListener("mouseup", (event) => {
        if (Date.now() - clicked < 300 && event.button < 2) {
          event.preventDefault();
          window.open(href, event.button === 1 ? "_blank" : "_self");
          window.focus();
        }
      });
    }
  }
}
